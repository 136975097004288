<template>
    <transition name="ghjfgj">
  <div :class="planFloorClass">
    <div>
      <router-link to="/plan" class="comeback-link">Повернутися до вибору поверху</router-link>
      <h1 class="main-title">Поверх №{{ floor }}</h1>

      <svg x="0px" y="0px" viewBox="0 0 840 760" class="plan-floor-shema" v-if="this.$building.floor[this.floor].sheme == '9'">
        <image class="plan-container-background" style="overflow:visible;" width="840" height="760" xlink:href="/assets/images/plan-9.jpg" >
        </image>
        <!-- <path class="plan-container-selector" d="M1044.1,385H878.9v329h-22.2v23.5l38.7,25.4h127.7v-50.2h20.3V583.1h50.2V455.5h-49.5V385z"/>
        <path class="plan-container-selector" d="M878.9,450.4H669.4v9.5v298.5h116.2V714h93.4V450.4z"/>
        <path class="plan-container-selector" d="M450.3,450.4h219.1l-0.6,308H553.2v-45.1H450.3V450.4z"/>
        <path class="plan-container-selector" d="M1044.1,160.2V385H781.1V82.7h110.5v77.5H1044.1z"/>
        <path class="plan-container-selector" d="M562,385h219.1l-0.6-302.3H664.3l-7.6,45.7H562V385z"/>
        <path class="plan-container-selector" d="M344.2,385h217.2V128.4H463l-13.3-45.7H344.2V385z"/>
        <path class="plan-container-selector" d="M343.6,451V82.7H231.2v45.7H101v141H55.3v106.7L101,392v-3.8h172.1V451H343.6z"/>
        <path class="plan-container-selector" d="M343.6,710.8V451.7h-71.1v-62.9H101.6v3.8H101v207.7l-45.7,4.4v106.1H343.6z"/>
        <path class="plan-container-selector" d="M318.2,711.4H55.3l0.6,106.1H101v179.7h217.2V711.4z"/>
        <path @click="goToRoom(floor,1)" class="plan-container-selector" d="M551.2,771.1H318.8v226.1h231.8l1.3-19.7h43.8l0.6-116.2l-1.9-36.8h-43.2V771.1z"/> -->
        <path @click="goToRoom(floor,1)" class="plan-container-selector"  :class="{'plan-selector-disabled': (this.$building.floor[this.floor].apartment['1'].status == 'reserved' ? true : false)}" d="M405 570H222V748H404.5L405.5 732.5H440L440.5 641L439 612H405V570Z" />
        <path @click="goToRoom(floor,2)" class="plan-container-selector"  :class="{'plan-selector-disabled': (this.$building.floor[this.floor].apartment['2'].status == 'reserved' ? true : false)}" d="M221.5 523L222 748H50.5V606.5H15V523.5L221.5 523Z" />
        <path @click="goToRoom(floor,3)" class="plan-container-selector"  :class="{'plan-selector-disabled': (this.$building.floor[this.floor].apartment['3'].status == 'reserved' ? true : false)}" d="M15.5 439L15 523H242V318H185V268H50.5V436L15.5 439Z" />
        <path @click="goToRoom(floor,4)" class="plan-container-selector"  :class="{'plan-selector-disabled': (this.$building.floor[this.floor].apartment['4'].status == 'reserved' ? true : false)}" d="M185.5 268H50.5H50V271L14.5 259V175H50.5V64H153V28H237.5V63.5H242V317.5H185.5V268Z" />
        <path @click="goToRoom(floor,5)" class="plan-container-selector"  :class="{'plan-selector-disabled': (this.$building.floor[this.floor].apartment['5'].status == 'reserved' ? true : false)}" d="M414 252H242.5V63H241V13.5H325L334.5 49H414V252Z" />
        <path @click="goToRoom(floor,6)" class="plan-container-selector"  :class="{'plan-selector-disabled': (this.$building.floor[this.floor].apartment['6'].status == 'reserved' ? true : false)}" d="M586 252H414.5V49H488L494 13H586V252Z" />
        <path @click="goToRoom(floor,7)" class="plan-container-selector"  :class="{'plan-selector-disabled': (this.$building.floor[this.floor].apartment['7'].status == 'reserved' ? true : false)}" d="M792.5 252H586V13H672.5V74H792.5V79H828.5V166L792.5 187.5V252Z" />
        <path @click="goToRoom(floor,8)" class="plan-container-selector"  :class="{'plan-selector-disabled': (this.$building.floor[this.floor].apartment['8'].status == 'reserved' ? true : false)}" d="M795.5 252.5H669V498H652V519L683.66 536H780V498H795.5V400.5L833 400V304H795.5V252.5Z" />
        <path @click="goToRoom(floor,9)" class="plan-container-selector"  :class="{'plan-selector-disabled': (this.$building.floor[this.floor].apartment['9-large'].status == 'reserved' ? true : false)}" d="M669 308H349V498H420V536H599V498H669V308Z" />
      </svg>
      <svg x="0px" y="0px" viewBox="0 0 840 760" class="plan-floor-shema" v-if="this.$building.floor[this.floor].sheme == '10'">
        <image class="plan-container-background" style="overflow:visible;" width="840" height="760" xlink:href="/assets/images/plan-10.jpg" >
        </image>
        <path @click="goToRoom(floor,1)" class="plan-container-selector" :class="{'plan-selector-disabled': (this.$building.floor[this.floor].apartment['1'].status == 'reserved' ? true : false)}" d="M402 570H219V748H401.5L402.5 732.5H437L437.5 641L436 612H402V570Z"/>
        <path @click="goToRoom(floor,2)" class="plan-container-selector" :class="{'plan-selector-disabled': (this.$building.floor[this.floor].apartment['2'].status == 'reserved' ? true : false)}" d="M218.5 523L219 748H47.5V606.5H12V523.5L218.5 523Z"/>
        <path @click="goToRoom(floor,3)" class="plan-container-selector" :class="{'plan-selector-disabled': (this.$building.floor[this.floor].apartment['3'].status == 'reserved' ? true : false)}" d="M12.5 439L12 523H239V318H182V268H47.5V436L12.5 439Z"/>
        <path @click="goToRoom(floor,4)" class="plan-container-selector" :class="{'plan-selector-disabled': (this.$building.floor[this.floor].apartment['4'].status == 'reserved' ? true : false)}" d="M182.5 268H47.5H47V271L11.5 259V175H47.5V64H150V28H234.5V63.5H239V317.5H182.5V268Z"/>
        <path @click="goToRoom(floor,5)" class="plan-container-selector" :class="{'plan-selector-disabled': (this.$building.floor[this.floor].apartment['5'].status == 'reserved' ? true : false)}" d="M411 252H239.5V63H238V13.5H322L331.5 49H411V252Z"/>
        <path @click="goToRoom(floor,6)" class="plan-container-selector" :class="{'plan-selector-disabled': (this.$building.floor[this.floor].apartment['6'].status == 'reserved' ? true : false)}" d="M583 252H411.5V49H485L491 13H583V252Z"/>
        <path @click="goToRoom(floor,7)" class="plan-container-selector" :class="{'plan-selector-disabled': (this.$building.floor[this.floor].apartment['7'].status == 'reserved' ? true : false)}"  d="M790 252H583.5V13H670V74H790V79H826V166L790 187.5V252Z"/>
        <path @click="goToRoom(floor,8)" class="plan-container-selector"  :class="{'plan-selector-disabled': (this.$building.floor[this.floor].apartment['8'].status == 'reserved' ? true : false)}" d="M792.5 252.5H666V498H649V519L680.66 536H777V498H792.5V400.5L830 400V304H792.5V252.5Z"/>
        <path @click="goToRoom(floor,9)" class="plan-container-selector"  :class="{'plan-selector-disabled': (this.$building.floor[this.floor].apartment['9'].status == 'reserved' ? true : false)}" d="M598 497.5H665.5V299H510V532.5H598V497.5Z"/>
        <path @click="goToRoom(floor,10)" class="plan-container-selector"  :class="{'plan-selector-disabled': (this.$building.floor[this.floor].apartment['10'].status == 'reserved' ? true : false)}" d="M423 532.5H509.5V299H345.5V498.33H423V532.5Z"/>
      </svg>
    </div>
  </div>
</transition>
</template>

<script>
export default {
  methods: {
    goToRoom(floor, apartment) {
      // return console.log(floor, apartment)
      return this.$router.push(`/plan/floor/${floor}/apartment/${apartment}`);
      // return console.log(floor);
      // return this.$router.push('gallery');
    },
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;

      if (addRemoveClass === 'addClass') {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    }
  },
  props: ['floor'],
  data() {
    return {
      planFloorClass: 'plan-floor-container',
    }
  },
  mounted: function () {
    // this.planFloorClass = this.planFloorClass + ' selected-floor'
    // this.planFloorClass = this.planFloorClass + ' selected-floor'
    this.toggleBodyClass('addClass', 'plan-page')
    setTimeout(() => {  this.toggleBodyClass('addClass', 'selected-floor') }, 10);
  },
  destroyed() {
    setTimeout(() => {  this.toggleBodyClass('removeClass', 'selected-floor') }, 10);
  },
  metaInfo() {
      return {
          title: `➊ ЖБК GENTLEMAN park - Досконалість у кожному будинку - поверх ${this.floor}`,
          meta: [
              { name: 'description', content: '➤ Gentleman Park - це команда молодих і активних спеціалістів з сучасним поглядом на прості речі. Ми будуємо елітні будинки з витонченим дизайном, разом з тим ми цінуємо комфорт і практичність створюваного простору. Ми прагнемо зробити життя у нашому місті стильним та затишним ☎️ +38 098 099 0707 '}
              // { property: 'og:title', content: this.userData.name + ' - Epiloge'},
              // { property: 'og:site_name', content: 'Epiloge'},
              // { property: 'og:description', content: 'Connect and follow ' + this.userData.name + ' on Epiloge - ' + this.userData.tagline},
              // {property: 'og:type', content: 'profile'},
              // {property: 'og:url', content: 'https://epiloge.com/@' + this.userData.username},
              // {property: 'og:image', content: this.aws_url + '/users/' + this.userData.profileurl + '-main.jpg' }
          ]
      }
  }
  // beforeCreate: function() {
  //     document.body.className = 'plan-page selected-floor';
  // }
}
</script>
